export const commonEmailDomains: string[] = [
    'gmail.com',
    'hotmail.com',
    'msn.com',
    'outlook.com',
    'yahoo.com',
    'protonmail.com',
    'aol.com',
    'live.com',
    'yahoo.co.uk',
    'hotmail.co.uk',
    'hotmail.fr',
    'yahoo.co.in',
    'icloud.com',
    'me.com',
    'mail.ru'
];
